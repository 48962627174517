import React, {Component} from 'react';
import '../assets/css/GameOver.css';

class GameOver extends Component{

    render(){
        return(
            <div className='gameOverDIV'>
                 <span className='gameOverText' >Game Over</span>
            </div>
        );
    }


}

export default GameOver;