import React, {Component} from 'react';

//Importar componentes
import BackGround from './components/BackGround';
import Countdown from './components/Countdown';
import Score from './components/Score';
import Lifes from './components/Lifes';
import Restart from './components/Restart';
import Pause from './components/Pause';
import GameOver from './components/GameOver';
import Balloon from './components/Balloon';
import BSO from './assets/sounds/soundTrack.mp3';
import GameOverSound from './assets/sounds/gameOver.wav';

//Gobal variables
const MaxBalloonInScreen = 16;
const MaxLevel = 15;
const IntervalCalc = 2000;

class App extends Component{

  constructor(props) {
    super(props);
    this.state = { 
      score: 0,
      lifes: 10,
      balloons : [],
      numberBalloon: 0,
      gameOver : false,
      bso : new Audio(BSO),
      gameOverSound: new Audio(GameOverSound),
      level:1,
      interval:undefined,
      isPause: false
    };
      this.state.bso.addEventListener('ended', function() {
        this.currentTime = 0;
        this.play();
    }, false); 
  }

  

  componentDidMount(){
    this.state.bso.play();
    this.StartInterval(2000/this.state.level) 

}

  //My Events
  StartInterval = (_interval)=>{
    this.setState({
      interval: setInterval(() => {
        if(!this.state.isPause){
          if(this.state.gameOver === true){
            clearInterval(this.state.interval);
          }
          else{
            if(this.state.balloons.length < MaxBalloonInScreen){
              this.setState({
                numberBalloon : this.state.numberBalloon + 1,
              })
              this.setState({
              balloons : this.state.balloons.concat([<Balloon 
                  key={this.state.numberBalloon} 
                  numberBalloon={this.state.numberBalloon}
                  pause= {this.state.isPause}
                  clickHandler={this.KillBalloon}
                  loseLife={this.LoseLife}
                  />])
              })
            }
          }
        }
      },_interval)
    })
     
  }

  KillBalloon = (numBalloonToDestroy) =>{
    let index = undefined;

    for( var i = 0; i<this.state.balloons.length ; i++){
       if(this.state.balloons[i].props.numberBalloon === numBalloonToDestroy){
        index = i;
          break;
       }
    }

    this.state.balloons.splice(index,1);
    this.setState({ 
      score: this.state.score + 1,
    });

    if(Uplevel(this.state.score)){
      if(this.state.level < MaxLevel){
        this.setState({
          level: this.state.level + 1 
        })
        clearInterval(this.state.interval);
        this.StartInterval(IntervalCalc/this.state.level)
      }
    }
  }

  LoseLife = (numBalloonToDestroy) =>{
    let index = undefined;

    for( var i = 0; i<this.state.balloons.length ; i++){
       if(this.state.balloons[i].props.numberBalloon === numBalloonToDestroy){
        index = i;
          break;
       }
    }

    this.state.balloons.splice(index,1);
    this.setState({ 
      lifes: this.state.lifes - 1,
    });

    if(this.state.lifes === 0){
        this.GameOver();
    }
  }

  GameOver = () => {
    this.setState({
      gameOver: true,
      balloons:[],
      bso: this.state.bso.pause(),
      gameOverSound: this.state.gameOverSound.play()
    })

  }

  ActivePause = () => {
    if(this.state.isPause){
      this.setState({
        isPause:false
      });
      this.state.bso.play();
    }else{
      this.setState({
        isPause:true
      });
      this.state.bso.pause();
    }
  }

  RenderGame = () => {
    if(this.state.gameOver === true){
      return ( 
        <div id="AppCount" className="App">
          <BackGround name="ballonIco"/>
          <Score score={this.state.score}/>
          <Lifes lifes={this.state.lifes}/>
          <Restart/>
          <GameOver/>
        </div>
        );
    }
    else{
      return ( 
        <div id="AppCount" className="App">
          <BackGround name="ballonIco"/>
          <Score score={this.state.score}/>
          <Lifes lifes={this.state.lifes}/>
          <Restart/>
          <Pause clickHandler={this.ActivePause}/>
          <Countdown/>
          <div>
            {this.state.balloons}
          </div>
        </div>
        );
    }
  
  }

  render(props, context){
    return (
      this.RenderGame()
    );
  }
}



function Uplevel(score){
  if(score%5 === 0)
    return true;
  else
    return false;
}

export default App;
