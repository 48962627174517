import React, {Component} from 'react';
import balloon1 from '../assets/images/Balloon_1.png';
import balloon2 from '../assets/images/Balloon_2.png';
import balloon3 from '../assets/images/Balloon_3.png';
import balloon4 from '../assets/images/Balloon_4.png';
import popSound from '../assets/sounds/pop.wav';
import ohNoSound from '../assets/sounds/ohNo.mp3';
import '../assets/css/Balloon.css';



class Balloon extends Component{

    constructor(props) {
        super(props);

        this.state = {
            img:undefined,
            size: 7,
            x: undefined,
            y: undefined,
            numberBalloon: props.numberBalloon,
            popSound: new Audio(popSound),
            ohNoSound: new Audio(ohNoSound),
        };  
    }

    componentWillMount(){
        switch(Math.floor(Math.random() * (5 - 1)) + 1){
            case 1:
                this.setState({
                    img: balloon1
                })
                break;

            case 2:
                this.setState({
                    img: balloon2
                })
                break;

            case 3:
                this.setState({
                    img: balloon3
                })
                break;
            case 4:
                this.setState({
                    img: balloon4
                })
                break;
            default:
                break;
        }
        this.setState({
            x: Math.floor(Math.random() * 90) + 1,
            y:100 + this.state.size,
        });
    }

    componentDidMount(){
        const interval = setInterval(() => {
            if(!this.props.pause){
                Ascend(this);
                if(this.state.y < -15){
                    clearInterval(interval);
                    this.state.ohNoSound.play();
                    this.props.loseLife(this.state.numberBalloon);
                }
            }
            else{
                clearInterval(interval);
            }
        },10)   
    }

    //Events
    handlerClick = () => {
        this.state.popSound.play();
        this.props.clickHandler(this.state.numberBalloon);
    }

    render(props, context){
        const style = {
            top: this.state.y + 'vh',
            left: this.state.x + 'vw',
            width: this.state.size + 'vw',
            height: this.state.size + 'vw',
            textSize: '50px',
            position: 'absolute'
        };

      

        return(
            <img src={this.state.img} 
                className='balloon' 
                alt='Balloon' 
                style={style} 
                onMouseDown={this.handlerClick}
            />
        )
    
    }
}

//Functions
function Ascend(balloon){
    const A = 0.2;
    const frequency = 0.15;
    const angle = 500;
    const speed = 0.15;

    balloon.setState({
        y: balloon.state.y - speed,
        x: balloon.state.x + (A * Math.cos((frequency*balloon.state.y) + angle))
    });
}

export default Balloon;