import React from 'react';
import backGroundImage from '../assets/images/Background.jpg';
import '../assets/css/Background.css';


function BackGround (props){

    return(
        <img src={backGroundImage} className="backgroundImage" alt={props.name} />
    );
}

export default BackGround;