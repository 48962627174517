import React, {Component} from 'react';
import '../assets/css/Score.css';

class Score extends Component{

    render(){
        return(
            <div className='scoreDIV'>
                <span className='scoreText' >Score: </span>
                <span className='scoreValue' >{this.props.score}</span>
            </div>
        );
    }


}

export default Score;