import React, {Component} from 'react';
import balloon1 from '../assets/images/Balloon_1.png';
import '../assets/css/Lifes.css';

class Lifes extends Component{

    render(){
        return(
            <div className='lifeDIV'>
                 <img src={balloon1} className="lifeImg" alt='Balloon'/>
                 <span className='lifeText' > x </span>
                <span className='lifeValue' >{this.props.lifes}</span>
            </div>
        );
    }


}

export default Lifes;