import React, {Component} from 'react';
import pauseImg from '../assets/images/Pause.png';
import '../assets/css/Pause.css';

class Pause extends Component{


      //Events
      handlerClick = () => {
        this.props.clickHandler();
    }

    render(){
        return(
            <div className='pauseDIV'>
                 <img src={pauseImg} className="pauseImg" alt='Pause' onClick={this.handlerClick}/>
            </div>
        );
    }
}


export default Pause;