import React, {Component} from 'react';
import restartImg from '../assets/images/Restart.png';
import '../assets/css/Restart.css';

class Restart extends Component{

    render(){
        return(
            <div className='restartDIV'>
                 <img src={restartImg} className="restartImg" alt='Restart' onClick={Reload}/>
            </div>
        );
    }
}

function Reload(){
    window.location.reload(false);
}

export default Restart;